import React from 'react';
import { Link } from 'gatsby';
// import Layout from '../components/layout';

const Another = () => (
    <>
    <h1>Another</h1>
    <p>Another.</p>
    <Link to="/">&larr; back to home</Link>
    </>
);
  
export default Another;
